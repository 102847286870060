<template>
  <div id="all-activities">
    <div
      class="top"
      v-for="(list, i) in Activity"
      :key="i"
      @click="goActivitiesColumn(list.id)"
    >
      <img v-lazy="ActivityPic[i].pic[1]" />
      <div class="introduce">
        <h2>{{ list.activityName }}</h2>
        <div>
          <p>{{ list.activityDescribe }}</p>
          <!-- <p>1、活动期间全场商品打9.5折。</p>
          <p>
            2、参与活动品类包含:水果蔬菜、肉禽蛋奶、粮油调味、酒水饮料、
            休闲食品、个人护理、化妆品、清洁用品、日用百货等。
          </p>
          <p>3、活动时间2021年10月14日00:00:00至2021年11月11日23:59:59</p>
          <p>4、活动期间全场商品打9.5折。</p>
          <p>
            5、参与活动品类包含:水果蔬菜、肉禽蛋奶、粮油调味、酒水饮料、
            休闲食品、个人护理、化妆品、清洁用品、日用百货等。
          </p>
          <p>6、活动时间2021年10月14日00:00:00至2021年11月11日23:59:59</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "all-activities",
  data() {
    return {
      Activity: "",
      ActivityPic: "",
    };
  },
  created() {
    this.getActivityList();
  },
  methods: {
    //获取活动
    async getActivityList() {
      const data = await this.$API.homePage.getActivityList({
        object: {
          location: "1",
        },
        pageNum: 0,
        pageSize: 0,
      });
      this.Activity = data.data.data;
      this.ActivityPic = data.data.data.map((item) => {
        return {
          pic: item.platformPic.split(","),
        };
      });
    },
    //去活动详情
    goActivitiesColumn(id) {
      this.$router.push({ path: `ActivitiesColumn/${id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
#all-activities {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  border-top: 1px solid var(--subjectColor);
  .top {
    width: 1200px;
    position: relative;
    cursor: pointer;
    height: 360px;
    margin-bottom: 24px;
    img {
      width: 100%;
      height: 100%;
    }
    .introduce {
      width: 460px;
      height: 280px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      position: absolute;
      flex-direction: column;
      top: 40px;
      left: 60px;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 18%);
      // padding: 5px 16px;
      h2 {
        width: 460px;
        height: 48px;
        background: #e1b07b;
        opacity: 1;
        border-radius: 4px 4px 0px 0px;
        font-size: 22px;
        // font-family: Source Han Serif CN;
        // font-weight: bold;
        line-height: 48px;
        text-align: center;
        color: #ffffff;
      }
      div {
        // padding: 5px 6px 5px 18px;
        padding: 5px 16px;
        height: 232px;
        overflow-y: auto;
        p {
          margin: 10px 0;
          font-size: 14px;
          line-height: 19px;
          color: #666666;
        }
      }
    }
  }
}
</style>